html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F3F2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

textarea {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
}

input.text-input {
  border-bottom: 1px solid #D3D3D3;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
  height: 38px;
  outline-style: none;
  width: 100%;
  font-size: 16px;
}

input[type=password] {
  border-bottom: 1px solid #D3D3D3;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
  height: 38px;
  outline-style: none;
  width: 100%;
  font-size: 16px;
}

input[type=number] {
  border-bottom: 1px solid #D3D3D3;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: transparent;
  height: 38px;
  outline-style: none;
  width: 100%;
  font-size: 16px;
}

button {
  font-size: 1em;
}

textarea:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

form[action$="destroy"] button {
  color: #f44250;
}

.gradient-loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd); */
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gradient-loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#f07d6e, #84cdfa, #5ad1cd);
}

.gradient-loader span:nth-child(1) {
  filter: blur(2px);
}

.gradient-loader span:nth-child(2) {
  filter: blur(5px);
}

.gradient-loader span:nth-child(3) {
  filter: blur(10px);
}

.gradient-loader span:nth-child(4) {
  filter: blur(10px);
}

.gradient-loader:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #f1f1f1;
  border: 1px solid white;
  border-radius: 50%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header-mobile {
  width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  z-index: 100;
}

.header-menu {
  width: 100vw;
  position: absolute;
  top: 60px;
  left: 0;
  padding: 16px 24px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
}

.header-menu-space {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99;
}

.header {
  width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  align-items: center;
  z-index: 100;
}

.header-item {
  font-family: 'Maharlika';
  color: black;
  text-decoration: none;
}

.footer-mobile {
  width: 100vw;
  /* height: 60px; */
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 100;
  padding: 8px 24px;
  font-size: 12px;
}

.footer {
  width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  z-index: 100;
  padding: 0 24px;
}

.link {
  text-decoration: none;
}

#content {
  flex: 1;
  width: 100%;
  margin-top: 60px;
}

#content.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-full-page {
  height: calc(100vh - 60px);
  flex: 1;
  width: 100%;
  display: flex;
  background: #F2F3F2;
  padding: 24px;
}

.home-background {
  background-image: url('./assets/images/homeBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.clear-button {
  width: 160px;
  height: 45px;
  user-select: none;
  background: transparent;
  border: 2px solid #B3735A;
  border-radius: 0;
  color: #46483E;
  text-transform: lowercase;
  cursor: pointer;
}

.clear-button:active {
  background: #B3735A;
}

.solid-button {
  width: 160px;
  height: 45px;
  user-select: none;
  background: #CCBFB9;
  border: none;
  border-radius: 0;
  color: #46483E;
  text-transform: lowercase;
  outline: none;
  cursor: pointer;
}

.solid-button:active {
  background-color: #B5AAA5;
}

.solid-button:disabled {
  background-color: #afafaf;
  cursor: default;
}

.publish-btn+.publish-tooltip {
  opacity: 0;
}

.publish-btn:disabled:hover+.publish-tooltip {
  opacity: 1;
}

.publish-tooltip {
  position: absolute;
  top: 50px;
  width: 100%;
  background-color: white;
  padding: 12px;
  border-radius: 5px;
}

.publish-tooltip>div:not(:first-child) {
  line-height: 18px;
  font-size: 14px;
}

.tagline {
  font-family: 'Maharlika';
  font-size: 55px;
  color: #8D7A6F;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 44px;
  }
}

@font-face {
  font-family: 'Maharlika';
  src: local('Maharlika'), url(./assets/fonts/Maharlika-Regular.ttf) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

.input {
  border-bottom: 1px solid #D3D3D3;
  height: 30;
  outline-style: none;
  width: 300;
  margin-bottom: 20;
}

.sidenav {
  height: calc(100vh - 60px);
  width: 200px;
  background-color: #D3D3D3;
  padding: 24px 0 0 24px;
  flex-grow: 0;
  flex-shrink: 0;
  position: fixed;
}

.sidenav-item {
  height: 50px;
  line-height: 50px;
  padding-left: 12px;
  justify-content: center;
  cursor: pointer;
  font-family: 'Maharlika';
  color: #808080;
  font-size: 20px;
}

.sidenav-item.selected {
  background-color: #F2F3F2;
  border-radius: 5px 0 0 5px;
  color: black;
}

hr {
  border-bottom: 0.5px solid #B3735A;
  margin: 12px 0;
}

#sidenav-content {
  flex: 1;
  width: 100%;
  margin: 0 0 0 200px;
}

#sidenav-content.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

.info-header {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Maharlika';
}

.info-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 0;
}

.sortable-image:hover .sortable-image-controls {
  opacity: 1;
}

.sortable-image-controls {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-control-left {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, transparent);
}

.image-control-right {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, transparent);
}

.quiz-option {
  display: flex;
  border: 1px solid #8D7A6F;
  border-radius: 10px;
  width: 300px;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  font-family: 'Maharlika';
  cursor: pointer;
}

.quiz-option:last-child {
  margin-bottom: 0;
}

.quiz-option:hover {
  background-color: #E0E0E0;
}

.quiz-option:active {
  background-color: #D3D3D3;
}

.page-container {
  padding: 24px;
  position: relative;
}

.property-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;
  font-family: 'Maharlika';
}

.scroll-image-container {
  height: 75vh;
}

.slideshow-container {
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 5 / 7;
}

.slideshow-slider {
  white-space: nowrap;
  transition: ease 1000ms;
  height: 100%;
}

.slideshow-slide {
  object-fit: cover;
  width: 100%;
  height: 100%;
  user-select: none;
}

.scroll-image-container:hover .slideshow-controls {
  opacity: 1;
}

.slideshow-controls {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 15%;
  height: auto;
  aspect-ratio: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slideshow-controls-left {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, transparent);
  border-radius: 0 1000px 1000px 0;
}

.slideshow-controls-right {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, transparent);
  border-radius: 1000px 0 0 1000px;
}

.message-table {
  margin-top: 24px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.message-table-header {
  font-weight: bold;
  font-family: 'Maharlika';
}

.message-table-body {
  gap: 0;
}

.message-table-body tr:nth-child(odd) {
  background-color: #E0E0E0;
  color: black;
}

.message-table-body tr:hover {
  background-color: #808080;
  color: #fff;
  cursor: pointer;
}

.message-table-body>tr {
  line-height: 36px;
}

tr.bold {
  font-weight: 600;
}

tr.bold td:first-child {
  font-size: 40px;
  color: green;
  transform: translate(2px, -4px);
}

.matched-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.unverified-email {
  margin-left: 12px;
  cursor: pointer;
}

.property-date-picker {
  font-size: 16px;
  width: 100%;
}

.control-arrow {
  transform: translateY(calc((75vh / 2) - ((75vh * 5 / 7) * .3) / 2));
  width: 15%;
  height: calc((75vh * 5 / 7) * .3);
}

.control-arrow.control-next {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, transparent);
  border-radius: 1000px 0 0 1000px;
}

.control-arrow.control-prev {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, transparent);
  border-radius: 0 1000px 1000px 0;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
